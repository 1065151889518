.msg-wrapper{
    padding-inline: 4px;
    border: 2px solid rgb(61, 59, 59);
    color: white;
    font-family: 'Courier New', Courier, monospace;
    background: rgb(184, 32, 32);
    font-size: 0.8rem;
}
.msg-exists{
    background: rgb(30, 102, 30);
}
.avatar-contain img{
    /* background-size: contain !important; */
    object-fit: contain !important;
}

.blink_me {
    animation: blinker 2s cubic-bezier(0.88, -0.17, 0, 1.4) infinite;
}
  
@keyframes blinker {
    50% {
        background-color: white;
        color: rgb(184, 32, 32);
    }
}