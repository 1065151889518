.page-wrapper {
    height: 6in;
    width: 10.2in;
    display: flex;
    flex-direction: column;
    padding: 15mm;
}

.page-header { 
    text-align: center;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.report-table{
    width: 100%;
    padding: 0;
    margin-top: 5mm;
    /* border-bottom: 1px solid gray; */
    /* border-left: 1px solid gray; */
}

.data-number{
    text-align: right;
}

.report-table th,
.report-table td{
    font-size: 0.65rem;
    padding-inline: 0.19rem;    
}

.report-table th{
    border-block: 1px solid gray;
    border-right: 1px solid gray;
}

.report-table th:first-child{
    border-left: 1px solid gray;
}

.report-table td{
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
}

.report-table td:last-child{
    border-right: 1px solid gray;
}

.report-table th {
    font-weight: bold;
}

@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
        margin-block: 5mm;
        display: block;
        page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin-inline: 10mm;
    margin-block: 15mm;
  }