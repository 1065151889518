.my-table {
    padding: 0;
}

.my-table thead tr th,
.my-table tbody tr td {
    text-align: left;
    font-weight: normal;
}

.my-table thead tr th:first-child,
.my-table tbody tr td:first-child{
    padding-left: 12px;
}


.my-table tr{
    border-bottom: 0.01rem solid black;
}

.my-table tr:last-child {
    border-bottom: 0px;
}