@import '~antd/es/style/themes/default.less';
@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry

* {
  box-sizing: border-box;

}

.base-shadow{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2) !important;

}

.section-pagination .ant-pagination-item {
    border-radius: 50px !important;
}

.section-pagination .ant-pagination-item-link{
    border-radius: 50px !important;
}

.card-summary .ant-card-body{
padding: 8px 16px !important;
}

.bg-base-theme{
 background-color: #040F2A !important;

}

.text-white{
    color: white !important;
}

.base-text-color{
    color: #040F2A !important;
   }

.cursor-pointer{
    cursor: pointer !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left;
}

@set:{
    success: rgba(56, 158, 13, 1);
    error: rgba(212, 56, 13, 1);
    def:rgb(157, 157, 157);
    primary: rgba(9, 88, 217, 1);
    warning: rgba(212, 107, 8, 1);


}

each(@set,{
    .bg-badge-@{key}{
        background-color: rgba(@value, 0.1);
        color: @value;

    }
})

each(@set,{
    .bg-badge-block-@{key}{
        background-color: rgba(@value);
        color: white;
    }
})


each(@set,{
    .badge-cs-@{key}{
        padding: 2px 4px;
        min-width: max-content;
        border-radius: 2px;
        font-weight: bold;
        text-align: center;
        font-size: .8rem;
    }
})

.card-list{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.card-list:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.basepage-title{
    position: sticky;
          top: 0px;
          z-index: 98;
          width: 100%;
          padding: 8px 24px;
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.basepage-no-title{
    position: sticky;
          top: 0px;
          z-index: 90;
          background-color: #F0F1F5;
          width: 100%;
        //   padding: 8px 24px;
        //   box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}


.rowslist-inline-flex{
    display: inline-flex;
    gap:4px;
    flex-wrap: wrap;
    align-items:center;
}

.list-base{
    background-color: white;
     padding: 4px 16px !important;
}


.text-right {
    text-align: right !important;
}

.text-sub-title{
    color: rgb(157, 157, 157) !important; 
}
.text-sub-title span{
    color: rgb(157, 157, 157)  !important; 
}
.wrap-content{
    padding: 60px;
}

.text-bold{
    font-weight: bold;
}

.uppercase-first-char::first-letter{
    text-transform:capitalize;
    

}

.basepage-title-text{
    margin-bottom: 0 !important;
    font-weight: bold;
    font-size: 1.3rem;
}

.wrap-content-login{
    background-color: white;
    border-radius: 8px;
    width: 340px;
    padding: 28px;
}

each(range(4),{
    .p-@{value}{
        padding: (@value * 4px) !important; 

    }
})

each(range(4),{
    .my-@{value}{
        margin-top: (@value * 4px) !important; 
        margin-bottom: (@value * 4px) !important;
    }
})

each(range(4),{
    .mt-@{value}{
        margin-top: (@value * 4px) !important; 
    }
})

each(range(4),{
    .mb-@{value}{
        margin-bottom: (@value * 4px) !important; 
    }
})


@media (max-width: 680px) {
    .ant-picker-dropdown{
        z-index: 1070 !important;
    }
    .ant-picker-panels {
        flex-direction: column !important;
        max-height: calc(100vh - 200px) !important;
        overflow: auto !important;

    }
    
}


@primary-color: #1890ff;