.home-button{
    display:flex; 
    flex-direction:column; 
    justify-content:center; 
    align-items:center;
    padding: 8px;
    color: #fff;
    width: 128px;
    height: 128px;
    cursor: pointer;
    border-radius: 8px;
}
.home-button-small{
    width: 100px;
    height: 100px;
}
.home-button span{
    color: #fff;
}
.flight {
    background-color: #1c456f;
}
.flight:hover{
    background-color: #092643;
}
.domestic{
    background-color: #1c566f;
}
.domestic:hover{
    background-color: #0a3649;
}
.dashboard{
    background-color: #269498;
}
.dashboard:hover{
    background-color: #1a7b7e;
}
.vessel{
    background-color: #269861;
}
.vessel:hover{
    background-color: #137144;
}
.passengers{
    background-color: #722698;
}
.passengers:hover{
    background-color: #5f1a7c;
}
.others{
    background-color: #988d26;
}
.others:hover{
    background-color: #756b16;
}
.telex{
    background-color: #984a26;
}
.telex:hover{
    background-color: #6f3316;
}

